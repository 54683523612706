<template>
  <b-card>
    <div class="d-flex justify-content-end mb-2">
      <b-button
        variant="primary
 			 ml-1  "
        v-on:click="timeline()"
      >
        TIMELINE
      </b-button>
      <b-button
        variant="warning
 			 ml-2 mr-2 "
        v-on:click="graph()"
      >
        GRAPH
      </b-button>
    </div>

    <div class="list-view mt-2">
      <b-row class="p-1 m-0 top-header mt-2">
        <b-col cols="12" md="6" class="d-flex">
          <div @click="goBack" class="mr-2 mt-1">
            <feather-icon icon="ArrowLeftIcon" size="24" />
          </div>
          <div>
            <h3 class="pr-1 mb-0 info-text">{{ info.hatch_id || "NA" }}</h3>
            <h6 class="pr-1 mb-0 info-text">Day {{ currentDay || "NA" }}</h6>
          </div>
        </b-col>
        <b-col cols="12" md="6" class="d-flex justify-content-end">
          <div class="success">
            Batch ID :
            {{ info.purchaseInfo ? info.purchaseInfo.batch_id : "N/A" }}
          </div>
          <div class="info">
            <span>Flock ID :{{ info.flock_id || "NA" }}</span>
          </div>
        </b-col>
      </b-row>
      <table class="mx-5 mt-1 mb-3 w-100">
        <tr>
          <th class="p-1">
            <b-img
              :src="require('@/assets/images/svg/reports/calendar.svg')"
              class="svg-img ml-1 mr-2"
            /><span class="font-weight-bold">Set Date </span>
          </th>
          <td>{{ moment(info.created_at).format("MMMM Do YYYY") }}</td>
        </tr>
        <tr>
          <th class="p-1">
            <b-img
              :src="require('@/assets/images/svg/reports/culled.svg')"
              class="svg-img"
            />
            <span class="font-weight-bold">Quantity </span>
          </th>
          <td>{{ info.total_eggs || "NA" }}</td>
        </tr>
        <tr>
          <th class="p-1">
            <b-img
              :src="
                require('@/assets/images/svg/reports/candlingInfertile.svg')
              "
              class="svg-img"
            />
            <span class="font-weight-bold">Candling</span>
          </th>
          <td>
            {{ info.total_vaccine_schedule_done }} /
            {{ info.total_candling_schedule }}
          </td>
        </tr>
        <tr>
          <th class="p-1">
            <b-img
              :src="
                require('@/assets/images/svg/reports/candlingInfertile.svg')
              "
              class="svg-img"
            />
            <span class="font-weight-bold">Candling Infertile Eggs </span>
          </th>
          <td>{{ infertile_egg || "NA" }}</td>
        </tr>

        <tr>
          <th class="p-1">
            <b-img
              :src="require('@/assets/images/svg/reports/weight.svg')"
              class="svg-img"
            />
            <span class="font-weight-bold">Chick Avg. Weight </span>
          </th>
          <td>{{ info.avg_weight || "NA" }}</td>
        </tr>

        <tr>
          <th class="p-1">
            <b-img
              :src="require('@/assets/images/svg/reports/cv.svg')"
              class="svg-img"
            />
            <span class="font-weight-bold">Vaccination </span>
          </th>
          <td>
            {{ info.total_vaccine_schedule_done }} /
            {{ info.total_vaccine_schedule }}
          </td>
        </tr>
      </table>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BFormDatepicker,
  BImg,
} from "bootstrap-vue";
import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BTooltip,
    BFormDatepicker,
    vSelect,
    BImg,
  },
  data() {
    return {
      info: {},
      infertile_egg: null,
      dayLeft: null,
      currentDay: null,
      totalDay: 21,
      machineId: null,
      hatchery_device_id: null,
    };
  },

  created: function () {
    this.getOverview();
  },
  methods: {
    moment() {
      return moment();
    },
    goBack() {
      this.$router.go(-1);
    },
    timeline() {
      this.$router.push({
        name: `apps-machine-device-timeline`,
        params: {
          hatch_id: this.hatch_id,
          hatchery_device_id: this.hatchery_device_id,
          machine_id: this.$route.params.machineId,
        },
      });
    },
    graph() {
      this.$router.push({
        name: `apps-machine-device-graph`,
        params: { hatchery_device_id: this.hatchery_device_id },
      });
    },
    getOverview() {
      this.hatchery_device_id = this.$route.params.hatchery_device_id;
      const hatch_id = this.$route.params.hatchId;
      const hatchery_id = this.$route.params.farmId;
      const machine_id = this.$route.params.machineId;

      axiosIns
        .get(
          `web/hatcheries/${hatchery_id}/Machine/${machine_id}/setter-hatch/overview?hatch_id=${hatch_id}`
        )
        .then((response) => {
          this.info = response.data.hatchOverview;
          this.dayLeft = response.data.hatchOverview.days_left;
          this.currentDay = this.totalDay - this.dayLeft;
          this.infertile_egg = response.data.infertile_egg;
        })
        .catch((error) => {
          const data = error.response.data.message;

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: data,
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
.list-view {
  background-color: #fafafa;
  box-shadow: 0px 3px 6px #38c06c33;
  height: 100%;
  padding: 0;
  margin: auto;
}
.head-text {
  font-size: 18px;
  color: #1d1d1d;
}
.info-text {
  color: #296db4;
  font-size: 21px;
}
.success {
  background-color: #d7f3e1;
  font-size: 11px;
  color: rgba(41, 109, 180, 1);
  display: flex;
  align-items: center;
  margin-right: 1rem;
  width: 150px;
  padding: 15px 15px;
  height: 33px;
  border-radius: 16px;
  justify-content: center;
}
.info {
  background-color: #5dadfd33;
  font-size: 11px;
  color: #5dadfd;
  display: flex;
  align-items: center;
  width: 150px;
  padding: 1rem 1rem;
  height: 33px;
  justify-content: center;
  border-radius: 16px;
}
.svg-img {
  margin-right: 10px;
}
table tr {
  margin: 1rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
